const CropPlanTypeString = {
  Auto: 'auto',
  Advanced: 'advanced',
  Manual: 'manual'
};

const CropPlanType = {
  Auto: 1,
  Advanced: 2,
  Manual: 3
};

const CropModelingStepForAuto = {
  SelectPlant: 1,
  CropModeling: 2
};

const CropModelingStepForAdvanced = {
  Configuration: 1,
  CropModeling: 2
};

const CropModelingStepForManual = {
  Configuration: 1,
  CropModeling: 2
};

const GrowingMethod = {
  Once: 1,
  Repeat: 2,
  Continuous: 3
};

const AssignmentMethod = {
  NumberOf: 1,
  Percentage: 2
};

const GroupType = {
  Seed: 0,
  Microgreen: 1,
  Mushroom: 2,
  Cutting: 3
};

const PlantTypeByContainer = {
  Small: 1,
  Large: 2,
  Mixed: 3
};

const PlacePlant = {
  Same: 1,
  Different: 2
};

const GrowingMethodString = {
  '-1': 'OFF',
  0: 'Seeding',
  1: 'Grow Once',
  2: 'Repeat Growing',
  3: 'Continuous Harvest',
  4: 'Future Assigned',
  5: 'Empty',
  6: 'Assigned Space Buffer'
};

const ContainerType = {
  '-1': 'OFF',
  0: 'Seeding',
  1: '36 Cell Tray',
  13: '72 Cell Tray',
  2: '162 Cell Tray',
  3: '242 Cell Tray',
  4: 'Microgreen Tray',
  5: 'Full Shelf Tray',
  6: 'Small Khôra Cube',
  7: 'Medium Khôra Cube',
  8: 'Large Khôra Cube',
  9: '1 Gallon',
  10: '3 Gallon',
  11: '5 Gallon',
  12: '10 Gallon'
};

const PositionString = { Front: 1, Middle: 2, Back: 3, 'Entire Tray': 4 };
const PositionType = { 1: 'Front', 2: 'Middle', 3: 'Back', 4: 'Entire Tray' };

const ShelfStatus = {
  '-2': 'Buffer',
  '-1': 'Empty',
  0: 'OFF',
  1: 'Growing',
  2: 'Khôra Seeding',
  3: 'Outside Seeding'
};

const ShelfStatusString = {
  Buffer: -2,
  Empty: -1,
  OFF: 0,
  Growing: 1,
  'Khôra Seeding': 2,
  'Outside Seeding': 3
};

const MaxVolumePerContainer = {
  '-1': -1,
  0: 0,
  1: 36,
  2: 162,
  3: 242,
  4: 1,
  5: 1,
  6: 18,
  7: 10,
  8: 8,
  9: 15,
  10: 5,
  11: 5,
  12: 2
};

const ContainerString = {
  OFF: -1,
  Seeding: 0,
  '36 Cell Tray': 1,
  '72 Cell Tray': 13,
  '162 Cell Tray': 2,
  '242 Cell Tray': 3,
  'Microgreen Tray': 4,
  'Full Shelf Tray': 5,
  'Small Khôra Cube': 6,
  'Medium Khôra Cube': 7,
  'Large Khôra Cube': 8,
  '1 Gallon': 9,
  '3 Gallon': 10,
  '5 Gallon': 11,
  '10 Gallon': 12
};

const ShelfTypePerContainer = {
  OFF: 0,
  Seeding: 0,
  '36 Cell Tray': 0,
  '72 Cell Tray': 0,
  '162 Cell Tray': 0,
  '242 Cell Tray': 0,
  'Microgreen Tray': 1,
  'Small Khôra Cube': 1,
  'Medium Khôra Cube': 1,
  'Large Khôra Cube': 1,
  'Full Shelf Tray': 2,
  '1 Gallon': 2,
  '3 Gallon': 2,
  '5 Gallon': 2,
  '10 Gallon': 2
};

const ModelingCategory = {
  2: 'Crop Costs',
  3: 'Profitability',
  4: 'Khôra Efficiency Gains (KEG)',
  5: 'Wholesale KEG Comparison',
  6: 'Retail KEG Comparison'
};

const GrowCategory = {
  Marketplace: 1,
  ContractedConsumer: 2,
  LeasedSpace: 3,
  Self: 4
};

export {
  CropPlanTypeString,
  CropPlanType,
  CropModelingStepForAuto,
  CropModelingStepForAdvanced,
  CropModelingStepForManual,
  GrowingMethod,
  AssignmentMethod,
  GroupType,
  PlantTypeByContainer,
  PlacePlant,
  GrowingMethodString,
  ContainerType,
  PositionString,
  PositionType,
  ShelfStatus,
  ShelfStatusString,
  MaxVolumePerContainer,
  ContainerString,
  ShelfTypePerContainer,
  ModelingCategory,
  GrowCategory
};
