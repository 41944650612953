// third-party
import { FormattedMessage } from 'react-intl';

// assets
// import { LineChartOutlined, IdcardOutlined, DatabaseOutlined } from '@ant-design/icons';

import CostManagementIcon from 'assets/icons/cost_management/Cost_Management.png';
import CostsIcon from 'assets/icons/cost_management/Costs.png';
import TaxAndDeliveryIcon from 'assets/icons/cost_management/Tax_Delivery.png';
import MarkupIcon from 'assets/icons/cost_management/Markup.png';
import GrowingPlanIcon from 'assets/icons/cost_management/Growing_Plan.png';
// import ProductIcon from 'assets/icons/cost_management/Product.png';
import SmallKhoraCubeIcon from 'assets/icons/cost_management/Small_Khora_Cube.png';
import MicrogreenTrayIcon from 'assets/icons/cost_management/Microgreen_Tray.png';
import MushroomIcon from 'assets/icons/cost_management/Mushroom.png';
import CuttingIcon from 'assets/icons/cost_management/Cutting.png';
import MissingCostIcon from 'assets/icons/cost_management/Missing_Cost.png';
import ImportExportIcon from 'assets/icons/cost_management/Importexport.png';

// ==============================|| MENU ITEMS - DASHBOARD ||============================== //

const cost_management = {
  id: 'group-cost',
  type: 'group',
  children: [
    {
      id: 'cost',
      title: <FormattedMessage id="Cost Management" />,
      type: 'collapse',
      icon: CostManagementIcon,
      children: [
        {
          id: 'costtaxanddelivery',
          title: <FormattedMessage id="Tax & Delivery Percentages" />,
          type: 'item',
          icon: TaxAndDeliveryIcon,
          url: '/cost/tax_delivery'
        },
        {
          id: 'costs',
          title: <FormattedMessage id="Costs" />,
          type: 'collapse',
          icon: CostsIcon,
          children: [
            {
              id: 'costsmallkhoracubes',
              title: <FormattedMessage id="Seeds" />,
              type: 'item',
              icon: SmallKhoraCubeIcon,
              url: '/cost/seed'
            },
            {
              id: 'costmicrogreentrays',
              title: <FormattedMessage id="Microgreens" />,
              type: 'item',
              icon: MicrogreenTrayIcon,
              url: '/cost/microgreen'
            },
            {
              id: 'costmushroom',
              title: <FormattedMessage id="Mushrooms" />,
              type: 'item',
              icon: MushroomIcon,
              url: '/cost/mushroom'
            },
            {
              id: 'costcuttings',
              title: <FormattedMessage id="Cuttings" />,
              type: 'item',
              icon: CuttingIcon,
              url: '/cost/cutting'
            }
          ]
        },
        {
          id: 'nocosts',
          title: <FormattedMessage id="Missing Growing Plan Costs" />,
          type: 'item',
          icon: MissingCostIcon,
          url: '/cost/missing_cost'
        },
        {
          id: 'markuppercentage',
          title: <FormattedMessage id="Profit Margin Override" />,
          type: 'item',
          icon: MarkupIcon,
          url: '/cost/markup'
        },
        {
          id: 'costgrowingplanwholeretail',
          title: <FormattedMessage id="Sale Prices & Profits" />,
          type: 'item',
          icon: GrowingPlanIcon,
          url: '/cost/price_profit'
        },
        {
          id: 'costimportexport',
          title: <FormattedMessage id="Import & Export Data" />,
          type: 'item',
          icon: ImportExportIcon,
          url: '/cost/import_export'
        }
      ]
    }
  ]
};

export default cost_management;
