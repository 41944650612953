// action - state management
import {
  CuttingCategory,
  GrowingMode,
  MicrogreenTrayCategory,
  MushroomCategory,
  SmallKhoraCubeCategory,
  WholesaleRetailCategory
} from 'utils/constants/cost';
import {
  CURRENT_CATEGORY,
  CURRENT_LOCATION,
  TAX_DELIVERY,
  WHOLESALE_RETAIL_PRICE_LIST,
  GROWING_PLAN_LIST_FOR_PRICE,
  GROWING_PLAN_COST_TO_EDIT,
  TOTAL_CATEGORY,
  GROWING_TYPE,
  COST_LIST,
  SEED_LIST_FOR_COST,
  CUTTING_LIST_FOR_COST,
  COST_TO_EDIT,
  VENDOR_LIST,
  COST_CATEGORY_LIST,
  CURRENT_RUNNING_CROP_PLAN,
  MARKUP_PERCENTAGES,
  INITIALIZE
} from './actions';

// initial state
export const initialState = {
  isInitialized: false,
  networkID: 0,
  locationID: 0,
  tax: 0,
  delivery: 0,
  vendorList: [],

  // cost category lists
  costCategoryList: [],

  // growing plan wholesale & retail
  category: WholesaleRetailCategory.Seeds,
  wholesaleRetailPriceList: [],
  growingPlanListForPrice: [],
  growingPlanToEdit: null,

  // list
  seedListForCost: [],
  cuttingListForCost: [],
  costList: [],
  costToEdit: null,
  // growing type
  growingMode: GrowingMode.Full,
  // category
  cubeCategory: SmallKhoraCubeCategory.Seeds,
  microgreenCategory: MicrogreenTrayCategory.Seeds,
  mushroomCategory: MushroomCategory['Spore Inoculation'],
  cuttingCategory: CuttingCategory.Cuttings,

  // current running crop plan
  curRunningCropPlan: null,

  // markup percentages
  markupPercentages: {
    wholesale_perc: 0,
    retail_perc: 0,
    wholesale_small_perc: 0,
    retail_small_perc: 0
  }
};

// ==============================|| AUTH REDUCER ||============================== //

const cost = (state = initialState, action) => {
  switch (action.type) {
    case INITIALIZE: {
      const { isInitialized } = action.payload;
      return {
        ...state,
        isInitialized
      };
    }
    case CURRENT_CATEGORY: {
      const { category } = action.payload;
      return {
        ...state,
        category
      };
    }
    case CURRENT_LOCATION: {
      const { networkID, locationID } = action.payload;
      return {
        ...state,
        networkID,
        locationID
      };
    }
    case TAX_DELIVERY: {
      const { tax, delivery } = action.payload;
      return {
        ...state,
        tax,
        delivery
      };
    }
    case WHOLESALE_RETAIL_PRICE_LIST: {
      const { wholesaleRetailPriceList, isInitialized } = action.payload;
      return {
        ...state,
        wholesaleRetailPriceList,
        isInitialized
      };
    }
    case GROWING_PLAN_LIST_FOR_PRICE: {
      const { growingPlanListForPrice } = action.payload;
      return {
        ...state,
        growingPlanListForPrice
      };
    }
    case GROWING_PLAN_COST_TO_EDIT: {
      const { growingPlanToEdit } = action.payload;
      return {
        ...state,
        growingPlanToEdit
      };
    }
    case TOTAL_CATEGORY: {
      const { cubeCategory, microgreenCategory, mushroomCategory, cuttingCategory } = action.payload;
      return {
        ...state,
        cubeCategory,
        microgreenCategory,
        mushroomCategory,
        cuttingCategory
      };
    }
    case GROWING_TYPE: {
      const { growingMode } = action.payload;
      return {
        ...state,
        growingMode
      };
    }
    case COST_LIST: {
      const { costList, isInitialized } = action.payload;
      return {
        ...state,
        costList,
        isInitialized
      };
    }
    case SEED_LIST_FOR_COST: {
      const { seedListForCost } = action.payload;
      return {
        ...state,
        seedListForCost
      };
    }
    case CUTTING_LIST_FOR_COST: {
      const { cuttingListForCost } = action.payload;
      return {
        ...state,
        cuttingListForCost
      };
    }
    case COST_TO_EDIT: {
      const { costToEdit } = action.payload;
      return {
        ...state,
        costToEdit
      };
    }
    case VENDOR_LIST: {
      const { vendorList } = action.payload;
      return {
        ...state,
        vendorList
      };
    }
    case COST_CATEGORY_LIST: {
      const { costCategoryList } = action.payload;
      return {
        ...state,
        costCategoryList
      };
    }
    case CURRENT_RUNNING_CROP_PLAN: {
      const { curRunningCropPlan } = action.payload;
      return {
        ...state,
        curRunningCropPlan
      };
    }
    case MARKUP_PERCENTAGES: {
      const { markupPercentages } = action.payload;
      return {
        ...state,
        markupPercentages
      };
    }
    default: {
      return { ...state };
    }
  }
};

export default cost;
