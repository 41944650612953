// action - account reducer
export const LOGIN = '@auth/LOGIN';
export const LOGOUT = '@auth/LOGOUT';
export const REGISTER = '@auth/REGISTER';
export const PUSHER = '@auth/PUSHER';
export const NOTIFICATION_LIST = '@auth/NOTIFICATION_LIST';
export const NOTIFICATION_COUNT = '@auth/NOTIFICATION_COUNT';
export const NOTIFICATION_CATEGORY = '@auth/NOTIFICATION_CATEGORY';

// action - dashboard
export const CRITICAL = '@auth/CRITICAL';
export const HIDE_CRITICAL = '@auth/HIDE_CRITICAL';

// action - guide, Khôra setup, user setup reducer
export const STATUS_INITIALIZE = '@setup/STATUS_INITIALIZE';
export const NETWORK = '@setup/NETWORK';
export const USER_SETUP = '@setup/USER_SETUP';

// action - crop plans
export const SEED_INITIALIZE = '@cropplan/SEED_INITIALIZE';
export const CUTTING_INITIALIZE = '@cropplan/CUTTING_INITIALIZE';
export const GROWINGPLAN_INITIALIZE = '@cropplan/GROWINGPLAN_INITIALIZE';
export const SELECTED_PLANTS = '@cropplan/SELECTED_PLANTS';
export const SELECTED_GROWING_PLANS = '@cropplan/SELECTED_GROWING_PLANS';
export const GROW_INTEREST = '@cropplan/GROW_INTEREST';
export const GAP_MANAGE = '@cropplan/GAP_MANAGE';
export const GROW_MODE = '@cropplan/GROW_MODE';
export const PLACE_PLANT = '@cropplan/PLACE_PLANT';
export const DURATION = '@cropplan/DURATION';
export const CROP_MODELING_STEP = '@cropplan/CROP_MODELING_STEP';
export const ESTIMATE_PARAMS = '@cropplan/ESTIMATE_PARAMS';
export const ASSIGNED_SHELVES = '@cropplan/ASSIGNED_SHELVES';
export const ASSIGNED_TRAYS = '@cropplan/ASSIGNED_TRAYS';
export const ASSIGNED_SEEDINGS = '@cropplan/ASSIGNED_SEEDINGS';
export const BUFFER_PERCENTAGE = '@cropplan/BUFFER_PERCENTAGE';
export const KEG_VALUE = '@cropplan/KEG_VALUE';
export const START_DATE = '@cropplan/START_DATE';
export const TOTAL_ACTIVE_KHORAS = '@cropplan/TOTAL_ACTIVE_KHORAS';
export const TOTAL_ACTIVE_REMAINING_SEEDING_TRAY = '@cropplan/TOTAL_ACTIVE_REMAINING_SEEDING_TRAY';
export const CURRENT_SHELVES_FOR_MANUAL = '@cropplan/CURRENT_SHELVES_FOR_MANUAL';
export const ADDED_GROWING_PLAN_ID = '@cropplan/ADDED_GROWING_PLAN_ID';
export const ADDED_GROWING_PLANS = '@cropplan/ADDED_GROWING_PLANS';
export const GROWING_GROUP_PER_SHELF = '@cropplan/GROWING_GROUP_PER_SHELF';

// action - cost management
export const CURRENT_CATEGORY = '@cost/CURRENT_CATEGORY';
export const CURRENT_LOCATION = '@cost/CURRENT_LOCATION';
export const TAX_DELIVERY = '@cost/TAX_DELIVERY';
export const WHOLESALE_RETAIL_PRICE_LIST = '@cost/WHOLESALE_RETAIL_PRICE_LIST';
export const GROWING_PLAN_LIST_FOR_PRICE = '@cost/GROWING_PLAN_LIST_FOR_PRICE';
export const GROWING_PLAN_COST_TO_EDIT = '@cost/GROWING_PLAN_COST_TO_EDIT';
export const TOTAL_CATEGORY = '@cost/TOTAL_CATEGORY';
export const GROWING_TYPE = '@cost/GROWING_TYPE';
export const COST_LIST = '@cost/COST_LIST';
export const SEED_LIST_FOR_COST = '@cost/SEED_LIST_FOR_COST';
export const CUTTING_LIST_FOR_COST = '@cost/CUTTING_LIST_FOR_COST';
export const COST_TO_EDIT = '@cost/COST_TO_EDIT';
export const VENDOR_LIST = '@cost/VENDOR_LIST';
export const COST_CATEGORY_LIST = '@cost/COST_CATEGORY_LIST';
export const GERMINATION_COST_CATEGORY_LIST = '@cost/GERMINATION_COST_CATEGORY_LIST';
export const TRAY_ASSEMBLY_COST_CATEGORY_LIST = '@cost/TRAY_ASSEMBLY_COST_CATEGORY_LIST';
export const DAILY_GROWING_COST_CATEGORY_LIST = '@cost/DAILY_GROWING_COST_CATEGORY_LIST';
export const HARVEST_COST_CATEGORY_LIST = '@cost/HARVEST_COST_CATEGORY_LIST';
export const CURRENT_RUNNING_CROP_PLAN = '@cost/CURRENT_RUNNING_CROP_PLAN';
export const MARKUP_PERCENTAGES = '@cost/MARKUP_PERCENTAGES';
export const INITIALIZE = '@cost/INITIALIZE';

// action - vendor management
export const VENDOR_TO_EDIT = '@vendor/VENDOR_TO_EDIT';
export const VENDOR_LIST_ALL = '@vendor/VENDOR_LIST_ALL';
export const VENDOR_TYPE_LIST = '@vendor/VENDOR_TYPE_LIST';

// action - customer management
export const CUSTOMER_TO_EDIT = '@customer/CUSTOMER_TO_EDIT';
export const CUSTOMER_LIST_ALL = '@customer/CUSTOMER_LIST_ALL';

// action - observation management
export const OBSERVATION_IS_INITIALIZED = '@observation/OBSERVATION_IS_INITIALIZED';
export const GROWING_PLAN_LIST_FOR_OBSERVATION = '@observation/GROWING_PLAN_LIST_FOR_OBSERVATION';
export const GROWING_PLAN_OBSERVATION_TO_EDIT = '@observation/GROWING_PLAN_OBSERVATION_TO_EDIT';
export const GROWING_PLAN_OBSERVATION_LIST_ALL = '@observation/GROWING_PLAN_OBSERVATION_LIST_ALL';
export const GROWING_PLAN_OBSERVATION_LOG_SUMMARY = '@observation/GROWING_PLAN_OBSERVATION_LOG_SUMMARY';
export const SELECTED_GROWING_PLAN_OBSERVATION_TYPE = '@observation/SELECTED_GROWING_PLAN_OBSERVATION_TYPE';
export const CROP_PLAN_LIST_FOR_OBSERVATION = '@observation/CROP_PLAN_LIST_FOR_OBSERVATION';
export const CROP_PLAN_OBSERVATION_TO_EDIT = '@observation/CROP_PLAN_OBSERVATION_TO_EDIT';
export const CROP_PLAN_OBSERVATION_LIST_ALL = '@observation/CROP_PLAN_OBSERVATION_LIST_ALL';
export const CROP_PLAN_OBSERVATION_LOG_SUMMARY = '@observation/CROP_PLAN_OBSERVATION_LOG_SUMMARY';
export const SELECTED_CROP_PLAN_OBSERVATION_TYPE = '@observation/SELECTED_CROP_PLAN_OBSERVATION_TYPE';

// action - list management
export const LIST_MANAGEMENT_CATEGORY = '@list/LIST_MANAGEMENT_CATEGORY';
export const LIST_MANAGEMENT_ISINITIALIZED = '@list/LIST_MANAGEMENT_ISINITIALIZED';
export const LIST_MANAGEMENT_SEED_LIST = '@list/LIST_MANAGEMENT_SEED_LIST';
export const LIST_MANAGEMENT_SEED_TO_EDIT = '@list/LIST_MANAGEMENT_SEED_TO_EDIT';
export const LIST_MANAGEMENT_MICROGREEN_LIST = '@list/LIST_MANAGEMENT_MICROGREEN_LIST';
export const LIST_MANAGEMENT_MICROGREEN_TO_EDIT = '@list/LIST_MANAGEMENT_MICROGREEN_TO_EDIT';
export const LIST_MANAGEMENT_CUTTING_LIST = '@list/LIST_MANAGEMENT_CUTTING_LIST';
export const LIST_MANAGEMENT_CUTTING_TO_EDIT = '@list/LIST_MANAGEMENT_CUTTING_TO_EDIT';
export const LIST_MANAGEMENT_MUSHROOM_LIST = '@list/LIST_MANAGEMENT_MUSHROOM_LIST';
export const LIST_MANAGEMENT_MUSHROOM_TO_EDIT = '@list/LIST_MANAGEMENT_MUSHROOM_TO_EDIT';
export const LIST_MANAGEMENT_LIGHTING_PLAN_LIST = '@list/LIST_MANAGEMENT_LIGHTING_PLAN_LIST';
export const LIST_MANAGEMENT_LIGHTING_PLAN_TO_EDIT = '@list/LIST_MANAGEMENT_LIGHTING_PLAN_TO_EDIT';
export const LIST_MANAGEMENT_NEW_LIGHTING_PLAN_LIST = '@list/LIST_MANAGEMENT_NEW_LIGHTING_PLAN_LIST';
export const LIST_MANAGEMENT_NEW_LIGHTING_PLAN_TO_EDIT = '@list/LIST_MANAGEMENT_NEW_LIGHTING_PLAN_TO_EDIT';
export const LIST_MANAGEMENT_ENVIRONMENT_PLAN_LIST = '@list/LIST_MANAGEMENT_ENVIRONMENT_PLAN_LIST';
export const LIST_MANAGEMENT_ENVIRONMENT_PLAN_TO_EDIT = '@list/LIST_MANAGEMENT_ENVIRONMENT_PLAN_TO_EDIT';
export const LIST_MANAGEMENT_IRRIGATION_PLAN_LIST = '@list/LIST_MANAGEMENT_IRRIGATION_PLAN_LIST';
export const LIST_MANAGEMENT_IRRIGATION_PLAN_TO_EDIT = '@list/LIST_MANAGEMENT_IRRIGATION_PLAN_TO_EDIT';
export const List_MANAGEMENT_LIGHTING_PLAN_VERSION = '@list/List_MANAGEMENT_LIGHTING_PLAN_VERSION';

export const LIST_MANAGEMENT_SEED_SEEDING_GROUP_LIST = '@list/LIST_MANAGEMENT_SEED_SEEDING_GROUP_LIST';
export const LIST_MANAGEMENT_SEED_PLANT_GROUP_LIST = '@list/LIST_MANAGEMENT_SEED_PLANT_GROUP_LIST';
export const LIST_MANAGEMENT_SEED_PLANT_VARIETY = '@list/LIST_MANAGEMENT_SEED_PLANT_VARIETY';
export const LIST_MANAGEMENT_SEED_PLANT_TYPE = '@list/LIST_MANAGEMENT_SEED_PLANT_TYPE';
export const LIST_MANAGEMENT_SEED_PHPREFS = '@list/LIST_MANAGEMENT_SEED_PHPREFS';
export const LIST_MANAGEMENT_SEED_ZONE_COUNTRY = '@list/LIST_MANAGEMENT_SEED_ZONE_COUNTRY';
export const LIST_MANAGEMENT_SEED_ZONES = '@list/LIST_MANAGEMENT_SEED_ZONES';
export const LIST_MANAGEMENT_SEED_VENDORS = '@list/LIST_MANAGEMENT_SEED_VENDORS';

export const LIST_MANAGEMENT_MUSHROOM_VARIETY = '@list/LIST_MANAGEMENT_MUSHROOM_VARIETY';

export const LIST_MANAGEMENT_CUTTING_GRAFTING = '@list/LIST_MANAGEMENT_CUTTING_GRAFTING';

export const LIST_MANAGEMENT_LIGHTING_PLAN_STEPS = '@list/LIST_MANAGEMENT_LIGHTING_PLAN_STEPS';
export const LIST_MANAGEMENT_LIGHTING_PLAN_STEP_TO_EDIT = '@list/LIST_MANAGEMENT_LIGHTING_PLAN_STEP_TO_EDIT';

// action - search
export const SEARCH_CATEGORY = '@search/SEARCH_CATEGORY';
export const SEARCH_CROP_PLAN_LIST = '@search/SEARCH_CROP_PLAN_LIST';
export const SEARCH_GROWING_PLAN_LIST = '@search/SEARCH_GROWING_PLAN_LIST';
export const SEARCH_SEED_LIST = '@search/SEARCH_SEED_LIST';
export const SEARCH_CUTTING_LIST = '@search/SEARCH_CUTTING_LIST';
export const SEARCH_MUSHROOM_LIST = '@search/SEARCH_MUSHROOM_LIST';
export const SEARCH_MICROGREEN_LIST = '@search/SEARCH_MICROGREEN_LIST';
export const SEARCH_VENDOR_LIST = '@search/SEARCH_VENDOR_LIST';

// action - schedule
export const SCHEDULE_NETWORK_LIST_ALL = '@schedule/SCHEDULE_NETWORK_LIST_ALL';
export const SCHEDULE_NETWORK_ID = '@schedule/SCHEDULE_NETWORK_ID';
export const SCHEDULE_PUSHER_DATA = '@schedule/SCHEDULE_PUSHER_DATA';
export const SCHEDULE_LIGHTING_PLAN_FOR_LOG = '@schedule/SCHEDULE_LIGHTING_PLAN_FOR_LOG';
export const SCHEDULE_CONSOLE_LOG = '@schedule/SCHEDULE_CONSOLE_LOG';
export const SCHEDULE_RUNNING_CROP_PLAN_ID = '@schedule/SCHEDULE_RUNNING_CROP_PLAN_ID';
export const SCHEDULE_CALENDAR_TYPE = '@schedule/SCHEDULE_CALENDAR_TYPE';
export const SCHEDULE_CROP_PLAN_COLOR = '@schedule/SCHEDULE_CROP_PLAN_COLOR';

// action - growing plan management
export const GROWING_PLAN_UPDATE_ID = '@growingplan/GROWING_PLAN_UPDATE_ID';
export const GROWING_PLAN_TO_EDIT = '@growingplan/GROWING_PLAN_TO_EDIT';
export const GROWING_PLAN_LIST_ALL = '@growingplan/GROWING_PLAN_LIST_ALL';
export const GROWING_PLAN_ACTIVE_STEP = '@growingplan/GROWING_PLAN_ACTIVE_STEP';
export const GROWING_PLAN_STATUS = '@growingplan/GROWING_PLAN_STATUS';
export const GROWING_PLAN_LIGHTING_PLAN_LIST = '@growingplan/GROWING_PLAN_LIGHTING_PLAN_LIST';
export const GROWING_PLAN_LIGHTING_PLAN_TO_EDIT = '@growingplan/GROWING_PLAN_LIGHTING_PLAN_TO_EDIT';
export const GROWING_PLAN_NEW_LIGHTING_PLAN_LIST = '@growingplan/GROWING_PLAN_NEW_LIGHTING_PLAN_LIST';
export const GROWING_PLAN_NEW_LIGHTING_PLAN_TO_EDIT = '@growingplan/GROWING_PLAN_NEW_LIGHTING_PLAN_TO_EDIT';
export const GROWING_PLAN_LIGHTING_PLAN_VERSION = '@growingplan/GROWING_PLAN_LIGHTING_PLAN_VERSION';
export const GROWING_PLAN_LIGHTING_PLAN_STEPS = '@growingplan/GROWING_PLAN_LIGHTING_PLAN_STEPS';
export const GROWING_PLAN_IS_LIGHTING_PLAN_SAVED = '@growingplan/GROWING_PLAN_IS_LIGHTING_PLAN_SAVED';
export const GROWING_PLAN_LIGHTING_PLAN_STEP_TO_EDIT = '@growingplan/GROWING_PLAN_LIGHTING_PLAN_STEP_TO_EDIT';
export const GROWING_PLAN_ENVIRONMENT_PLAN_LIST = '@growingplan/GROWING_PLAN_ENVIRONMENT_PLAN_LIST';
export const GROWING_PLAN_ENVIRONMENT_PLAN_TO_EDIT = '@growingplan/GROWING_PLAN_ENVIRONMENT_PLAN_TO_EDIT';
export const GROWING_PLAN_IS_ENVIRONMENT_PLAN_SAVED = '@growingplan/GROWING_PLAN_IS_ENVIRONMENT_PLAN_SAVED';
export const GROWING_PLAN_IRRIGATION_PLAN_LIST = '@growingplan/GROWING_PLAN_IRRIGATION_PLAN_LIST';
export const GROWING_PLAN_IRRIGATION_PLAN_TO_EDIT = '@growingplan/GROWING_PLAN_IRRIGATION_PLAN_TO_EDIT';
export const GROWING_PLAN_IS_IRRIGATION_PLAN_SAVED = '@growingplan/GROWING_PLAN_IS_IRRIGATION_PLAN_SAVED';

// action - Todo list management
export const TODO_LIST = '@todo/TODO_LIST';
export const TODO_TO_EDIT = '@todo/TODO_TO_EDIT';
export const TODO_STATUS = '@todo/TODO_STATUS';

// action - Shopping list
export const SHOPPING_NETWORK_LIST_ALL = '@shopping/SHOPPING_NETWORK_LIST_ALL';
export const SHOPPING_NETWORK_ID = '@shopping/SHOPPING_NETWORK_ID';

// action - Plant Placement
export const PLANT_PLACEMENT_INITIALIZE = '@plantplacement/PLANT_PLACEMENT_INITIALIZE';
export const PLANT_PLACEMENT_DATA = '@plantplacement/PLANT_PLACEMENT_DATA';
export const PLANT_PLACEMENT_TYPE = '@plantplacement/PLANT_PLACEMENT_TYPE';
export const PLANT_PLACEMENT_SELECTED_LIST = '@plantplacement/PLANT_PLACEMENT_SELECTED_LIST';
export const PLANT_PLACEMENT_ALL_SHELVES = '@plantplacement/PLANT_PLACEMENT_ALL_SHELVES';

// action - Payment
export const STRIPE_OPTIONS = '@payment/STRIPE_OPTIONS';
export const STRIPE_PROMISE = '@payment/STRIPE_PROMISE';
export const PAYMENT_METHOD = '@payment/PAYMENT_METHOD';
export const PAYMENT_METHOD_EDIT = '@payment/PAYMENT_METHOD_EDIT';
